import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { seo } from '../content/forwarding'
import Seo from '../components/Seo'
import Intro from '../components/Intro'
import TemplateBody from '../components/TemplateBody'
import { useLangContext } from '../context/lang.context'
import { s, colors, sCenteredSpaced } from '../style'
import Button from '../components/Button'
import Tiles from '../components/Tiles'
import Headline from '../components/Headline'
import Section from '../components/Section'
import { useFormContext } from '../context/form.context'
import { pageName } from '../content/_dictonary'

export default function ForwardingTemplate({ data }) {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  let forwarding = data.forwardings.nodes.find(
    (node) => node.lang.slug === lang
  )
  if (!forwarding) forwarding = data.forwardings.nodes[0]

  const {
    name,
    title,
    slug,
    keywords,
    image,
    body,
    description,
    createdAt,
    updatedAt,
    isoffice,
    city,
  } = forwarding

  // console.log(data)

  const imageData = getImage(image)

  const buttonIntro = {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citiți mai mult',
    },
    action: 'SCROLL',
  }

  const buttonQuote = {
    text: {
      en: 'Order transport',
      es: 'Transporte de pedidos',
      ro: 'Comanda de transport',
    },
    action: () => setFormOpen(true),
    glow: true,
  }

  const buttonParent = {
    text: {
      en: 'More about forwarding',
      es: 'Más sobre el envío',
      ro: 'Mai multe despre redirecționare',
    },
    link: '/forwarding/',
    dimmed: true,
  }

  return (
    <Layout>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={slug}
        keywords={keywords}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <Intro
        h={1}
        title={name}
        desc={seo.desc[lang]}
        image={imageData}
        button={buttonIntro}
      />
      <TemplateBody
        title={description}
        body={body.childMarkdownRemark.html.replaceAll('h1>', 'h2>')}
        image={imageData}
        imageCredits={image?.description}
        buttons={[buttonParent, buttonQuote]}>
        {isoffice &&
          data?.offices?.nodes
            .filter((office) => office.lang.slug === lang)
            .map((office, id) => (
              <div css={sOfficeContainer} key={id}>
                <span>Omida VLS</span>
                <h2 css={sTitle}>
                  {city} {pageName.office[lang]}
                </h2>
                <div>
                  <a
                    href={`https://www.google.pl/maps/search/${office.address}`}
                    target='_blank'
                    rel='noreferrer noopener'>
                    {office.address}
                  </a>
                  <a href={`mailto:${office.email}`}>{office.email}</a>
                  <a href={`tel:${office.phone}`}>{office.phone}</a>
                </div>
              </div>
            ))}
      </TemplateBody>
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

const buttonBack = {
  text: {
    en: 'Back to forwarding',
    es: 'Volver a reenvío',
    ro: 'Înapoi la redirecționare',
  },
  link: '/forwarding/',
}

const sOfficeContainer = {
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1.5rem',
  padding: '4rem',
  marginBottom: '4rem',
  [s.sm_down]: {
    borderRadius: '1rem',
    padding: '2rem',
  },
  backgroundColor: colors.text,

  span: {
    letterSpacing: '-0.05em',
    // opacity: 0.3,
    fontSize: '1rem',
    fontWeight: 700,

    color: colors.brand,
    [s.xs]: {
      paddingBottom: '0.5rem',
    },
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    a: {
      letterSpacing: '-0.025em',
      transition: 'opacity 0.3s ease-out',
      opacity: 0.6,
      fontWeight: 700,
      fontSize: '0.875rem',
      '&:hover': {
        opacity: 1,
      },
    },
    [s.xs]: {
      flexDirection: 'column',
      a: {
        fontSize: '1.125rem',
        marginBottom: '1rem',
      },
    },
  },
}

const sTitle = {
  fontSize: '3rem',
  [s.xs]: {
    fontSize: '2rem',
  },
}

export const query = graphql`
  query ($slug: String!, $city: String) {
    forwardings: allContentfulForwarding(filter: { slug: { eq: $slug } }) {
      nodes {
        lang {
          slug
        }
        id
        createdAt
        updatedAt
        slug
        name
        title
        description
        keywords
        isoffice
        address
        phone
        email
        city
        body {
          childMarkdownRemark {
            html
          }
        }
        image {
          description
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
          file {
            url
            fileName
          }
        }
      }
    }
    offices: allContentfulForwarding(filter: { city: { eq: $city } }) {
      nodes {
        lang {
          slug
        }
        name
        isoffice
        address
        phone
        email
        city
      }
    }
  }
`
