export const seo = {
  url: 'forwarding',
  title: {
    en: 'Forwarding services | Best Forwarding',
    es: 'Servicios de expedición | Mejor reenvío',
    ro: 'Servicii de expediere | Cea mai bună expediere',
  },
  desc: {
    en: 'Check what the forwarding does. Over 30 specialized forwarding branches of Omida VLS throughout Poland, ready to provide transport and logistics services.',
    es: 'Compruebe lo que hace el reenvío. Más de 30 sucursales de expedición especializadas de Omida VLS en toda Polonia, listas para proporcionar servicios de transporte y logística.',
    ro: 'Verificați ce face redirecționarea. Peste 30 de sucursale specializate de expediere ale Omida VLS în toată Polonia, gata să furnizeze servicii de transport și logistică.',
  },
  keywords: ['omida', 'logistics', 'forwarding', 'services', 'forwarder'],
}

export const intro = {
  title: {
    en: 'Forwarding',
    es: 'Forwarding',
    ro: 'Forwarding',
  },
  desc: {
    en: 'Check what the forwarding does. Over 30 specialized forwarding branches of Omida VLS throughout Poland, ready to provide transport and logistics services.',
    es: 'Compruebe lo que hace el reenvío. Más de 30 sucursales de expedición especializadas de Omida VLS en toda Polonia, listas para proporcionar servicios de transporte y logística',
    ro: 'Verificați ce face redirecționarea. Peste 30 de sucursale specializate de expediere ale Omida VLS în toată Polonia, gata să furnizeze servicii de transport și logistică.',
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citește mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'What is forwarding? Definition of freight forwarding.',
        es: '¿Qué es el transporte de carga? Definición de envío.',
        ro: 'Ce este redirecționarea? Definiția freight forwarding.',
      },
      texts: [
        {
          en: '<span>Forwarding is primarily <strong>people</strong> and the entire <strong>process of cargo movement</strong> (including transport, handling and storage) using the available means of road, sea, air and rail transport, in the result of which is <strong> delivery of the goods </strong> of the customer to the destination.</span>',
          es: '<span>El envío de carga es principalmente <strong> personas </strong> y todo el <strong> proceso de movimiento de carga </strong> (incluido el transporte, la recarga y el almacenamiento) utilizando los medios disponibles de transporte por carretera, mar, aire y ferrocarril. , cuyo resultado es la <strong> entrega de la mercancía </strong> del cliente al destino. </span>',
          ro: '<span>Expedierea se referă în primul rând la <strong>oameni</strong> și întregul <strong>proces de mișcare a mărfurilor</strong> (inclusiv transportul, manipularea și depozitarea) folosind mijloacele disponibile rutiere, maritime, aeriene și transport feroviar, în rezultatul căruia este <strong> livrarea mărfurilor </strong> a clientului la destinație.</span>',
        },
      ],
    },
    {
      headline: {
        en: 'Over 30 forwarding branches all over Poland',
        es: 'Más de 30 sucursales de envío en toda Polonia',
        ro: 'Peste 30 de sucursale de expediere în toată Polonia',
      },
      texts: [
        {
          en: 'Our branches are located in all voivodships in Poland. Read more about our divisions and find out what they specialize in.',
          es: 'Nuestras sucursales están ubicadas en todos los voivodatos de Polonia. Lea más sobre nuestras divisiones y descubra en qué se especializan.',
          ro: 'Sucursalele noastre sunt situate în toate voievodatele din Polonia. Citiți mai multe despre diviziile noastre și aflați în ce sunt specializate.',
        },
      ],
    },
  ],
}
